import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api, api_post, api_update } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import { getStatusClass } from '../utils';
import { BanknotesIcon } from '@heroicons/react/24/outline';
import { BriefcaseIcon, ClipboardDocumentListIcon, DocumentTextIcon, UserIcon, CreditCardIcon } from '@heroicons/react/24/outline';

const FinancingRequestDetail = () => {
    const { id } = useParams();
    const [financingRequest, setFinancingRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeTab, setActiveTab] = useState('Business');
    const [creditApplication, setCreditApplication] = useState(null);

    useEffect(() => {
        fetchFinancingRequest();
    }, [id]);

    const fetchFinancingRequest = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/${id}`
            );
            setFinancingRequest(result);
            const creditApplicationResult = await api(
                `${process.env.REACT_APP_API_URL}/credit-application/business/${result.business._id}`
            );
            setCreditApplication(creditApplicationResult[0])
        } catch (err) {
            setError('Error loading financing request data');
            toast.error('Failed to load financing request details');
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async () => {
        try {
            const updatedFinancingRequest = {
                ...financingRequest,
                status: 'Approved',
            };

            await updateFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request approved');
            setFinancingRequest(updatedFinancingRequest);
        } catch (err) {
            toast.error('Failed to approve financing request');
        }
    };

    const handleReject = async () => {
        try {
            const updatedFinancingRequest = {
                ...financingRequest,
                status: 'Rejected',
            };

            await updateFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request rejected');
            setFinancingRequest(updatedFinancingRequest);
        } catch (err) {
            toast.error('Failed to reject financing request');
        }
    };

    const updateFinancingRequest = async (updatedFinancingRequest) => {
        try {
            await api_update(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/${id}`,
                updatedFinancingRequest
            );
            setFinancingRequest(updatedFinancingRequest);
            toast.success('Financing request updated successfully');
        } catch (err) {
            toast.error('Failed to update financing request');
            console.error(err);
        }
    };

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;
    if (!financingRequest) return null;

    const {
        invoice,
        customer,
        business,
        achInfo,
        status,
        amount,
        product,
        createdAt,
        updatedAt,
        project,
        offers
    } = financingRequest;

    const renderValue = (value, isCurrency = false) => {
        if (value !== undefined && value !== null) {
            return isCurrency ? `$${value}` : value;
        } else {
            return <span className="text-yellow-500">Missing</span>;
        }
    };

    return (
        <div className="flex flex-col pb-36">
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Financing Request Detail'} showBackButton="true" />
            <div className="container mx-auto my-10">
                <div className="mx-6">
                    {/* Financing Request Info */}
                    <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
                        <h2 className="text-3xl font-medium mb-4 flex items-center">
                            <BanknotesIcon className="w-8 h-8 mr-2" />
                            Financing Request</h2>
                        <div className="grid gap-y-2 border rounded-md">
                            {/* Each row */}
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Invoice ID:</span>
                                <span>{renderValue(invoice?._id)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Amount:</span>
                                <span>{renderValue(amount, true)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Product:</span>
                                <span>{renderValue(product)}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Status:</span>
                                <span className={`${getStatusClass(status)} capitalize`}>{status}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Created At:</span>
                                <span>{renderValue(new Date(createdAt).toLocaleString())}</span>
                            </div>
                            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
                                <span className="font-medium">Updated At:</span>
                                <span>{renderValue(new Date(updatedAt).toLocaleString())}</span>
                            </div>
                        </div>
                    </div>

                    {/* Tabs */}
                    <div className="mb-4">
                        <div className="flex gap-x-4">
                            {['Business', 'Project', 'Customer', 'ACH Info', "Credit Application"].map((tab) => (
                                <button
                                    key={tab}
                                    className={`px-6 py-2 font-semibold hover:scale-105 transition-all border rounded-lg 
                                        ${activeTab === tab
                                            ? 'bg-white shadow-sm'
                                            : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab(tab)}
                                >
                                    {tab}
                                </button>
                            ))}
                            {financingRequest.product === 'Pay Later' && (
                                <button
                                    key="Bill"
                                    className={`px-6 py-2 font-semibold hover:scale-105 transition-all border rounded-lg 
                                        ${activeTab === 'Bill'
                                            ? 'bg-white shadow-sm'
                                            : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab('Bill')}
                                >
                                    Bill
                                </button>
                            )}
                            {financingRequest.product === 'Fund Now' && (
                                <button
                                    key="Invoice"
                                    className={`px-4 py-2 -mb-px font-semibold ${activeTab === 'Invoice'
                                        ? 'border-t border-l border-r rounded-t text-blue-700'
                                        : 'text-gray-700'
                                        }`}
                                    onClick={() => setActiveTab('Invoice')}
                                >
                                    Invoice
                                </button>
                            )}
                        </div>
                    </div>

                    {/* Tab Content */}
                    <div className="mb-8">
                        {activeTab === 'Business' && <BusinessComponent business={business} />}
                        {activeTab === 'Project' && <ProjectComponent project={project} />}
                        {activeTab === 'Customer' && <CustomerComponent customer={customer} />}
                        {activeTab === 'Bill' && <BillsComponent bill={invoice} />}
                        {activeTab === 'Invoice' && <InvoicesComponent invoice={invoice} />}
                        {activeTab === 'ACH Info' && <ACHInfoComponent achInfo={achInfo} />}
                        {activeTab === 'Credit Application' && <CreditApplicationComponent creditApplication={creditApplication} />}
                    </div>

                    {/* Offers Section */}
                    <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
                        <OffersSection
                            financingRequest={financingRequest}
                            updateFinancingRequest={updateFinancingRequest}
                            billId={invoice._id}
                        />
                    </div>

                    {/* Approve and Reject Buttons */}
                    <div className="mt-6 flex space-x-4">
                        {/* <button
                            onClick={handleApprove}
                            className="bg-green-400 hover:bg-green-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                        >
                            Approve
                        </button> */}
                        <button
                            onClick={handleReject}
                            className="bg-red-400 hover:bg-red-600 text-white font-medium py-2 px-4 rounded transform hover:scale-105 transition-transform"
                        >
                            Reject
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
const BusinessComponent = ({ business }) => {
    const fields = [
        { label: 'Business ID', value: business._id },
        { label: 'Name', value: business.name },
        { label: 'Type', value: business.type },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <BriefcaseIcon className="w-8 h-8 mr-2" />
                Business Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>{field.value || <span className="text-yellow-500">Missing</span>}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ProjectComponent = ({ project }) => {
    const fields = [
        { label: 'Project ID', value: project._id },
        { label: 'Name', value: project.name },
        { label: 'Project Owner', value: project.projectOwner },
        { label: 'Main Contact', value: project.mainContact },
        { label: 'Main Contact Email', value: project.mainContactEmail },
        { label: 'Owner ID', value: project.owner },
        { label: 'Document ID', value: project.document },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <ClipboardDocumentListIcon className="w-8 h-8 mr-2" />
                Project Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>{field.value || <span className="text-yellow-500">Missing</span>}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const CustomerComponent = ({ customer }) => {
    const fields = [
        { label: 'Customer ID', value: customer._id },
        { label: 'Name', value: customer.name },
        { label: 'Email', value: customer.email },
        { label: 'Business ID', value: customer.business },
        { label: 'Roles', value: customer.roles },
        { label: 'Has Free Access', value: customer.hasFreeAccess ? 'Yes' : 'No' },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <UserIcon className="w-8 h-8 mr-2" />
                Customer Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined ? field.value : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const ACHInfoComponent = ({ achInfo }) => {
    const fields = [
        { label: 'ACH ID', value: achInfo._id },
        { label: 'Account Number', value: achInfo.accountNumber },
        { label: 'Routing Number', value: achInfo.routingNumber },
        { label: 'Bank Name', value: achInfo.bankName },
        { label: 'Account Type', value: achInfo.accountType },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <CreditCardIcon className="w-8 h-8 mr-2" />
                ACH Information
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined && field.value !== null && field.value !== ''
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const BillsComponent = ({ bill }) => {
    const fields = [
        { label: 'Bill ID', value: bill._id },
        { label: 'Invoice Number', value: bill.invoiceNumber },
        { label: 'Vendor Name', value: bill.vendorName },
        { label: 'Vendor Contact First Name', value: bill.vendorContactFirstName },
        { label: 'Vendor Contact Last Name', value: bill.vendorContactLastName },
        { label: 'Vendor Contact Email', value: bill.vendorContactEmail },
        { label: 'Vendor Contact Phone Number', value: bill.vendorContactPhoneNumber },
        { label: 'Vendor Contact Company Address', value: bill.vendorContactCompanyAddress },
        { label: 'Subtotal', value: bill.subtotal },
        { label: 'Tax', value: bill.tax },
        {
            label: 'Due Date',
            value: bill.dueDate ? new Date(bill.dueDate).toLocaleDateString() : null,
        },
        {
            label: 'Order Date',
            value: bill.orderDate ? new Date(bill.orderDate).toLocaleDateString() : null,
        },
        {
            label: 'Date Uploaded',
            value: bill.dateUploaded ? new Date(bill.dateUploaded).toLocaleString() : null,
        },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Bill Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined ? field.value : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const InvoicesComponent = ({ invoice }) => {
    const fields = [
        { label: 'Invoice ID', value: invoice._id },
        { label: 'Invoice Number', value: invoice.invoiceNumber },
        { label: 'Vendor Name', value: invoice.vendorName },
        { label: 'Vendor Contact First Name', value: invoice.vendorContactFirstName },
        { label: 'Vendor Contact Last Name', value: invoice.vendorContactLastName },
        { label: 'Vendor Contact Email', value: invoice.vendorContactEmail },
        { label: 'Vendor Contact Phone Number', value: invoice.vendorContactPhoneNumber },
        { label: 'Vendor Contact Company Address', value: invoice.vendorContactCompanyAddress },
        { label: 'Subtotal', value: invoice.subtotal },
        { label: 'Tax', value: invoice.tax },
        {
            label: 'Due Date',
            value: invoice.dueDate ? new Date(invoice.dueDate).toLocaleDateString() : null,
        },
        {
            label: 'Order Date',
            value: invoice.orderDate ? new Date(invoice.orderDate).toLocaleDateString() : null,
        },
        {
            label: 'Date Uploaded',
            value: invoice.dateUploaded ? new Date(invoice.dateUploaded).toLocaleString() : null,
        },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Invoice Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            }`}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span>
                            {field.value !== undefined ? field.value : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const CreditApplicationComponent = ({ creditApplication }) => {
    const { applicationData } = creditApplication;
    console.log(creditApplication);

    const fields = [
        { label: 'Application ID', value: creditApplication._id },
        { label: 'Status', value: creditApplication.status },
        { label: 'Company Legal Name', value: applicationData.companyLegalName },
        { label: 'Company Address', value: applicationData.companyAddress },
        { label: 'Date of Incorporation', value: applicationData.dateOfIncorporation },
        { label: 'EIN Number', value: applicationData.einNumber },
        { label: 'Phone Number', value: applicationData.phoneNumber },
        { label: 'Website', value: applicationData.website },
        { label: 'Types of Projects', value: applicationData.typesOfProjectsYouWorkOn },
        { label: 'Average Project Value Per Month', value: applicationData.averageProjectValuePerMonth },
        { label: 'Monthly Revenue', value: applicationData.monthlyRevenue },
        { label: 'Monthly Debt Payment', value: applicationData.monthlyDebtPayment },
        { label: 'Bank Account File', value: applicationData.bankAccountFile },
        { label: 'QuickBooks File', value: applicationData.quickbooksFile },
        { label: 'Project Management File', value: applicationData.projectManagementFile },
        {
            label: 'Created At',
            value: new Date(creditApplication.createdAt).toLocaleString(),
        },
        {
            label: 'Updated At',
            value: new Date(creditApplication.updatedAt).toLocaleString(),
        },
    ];

    return (
        <div className="bg-white shadow-sm border rounded-lg p-6 mb-8">
            <h2 className="text-3xl font-medium mb-4 flex items-center">
                <DocumentTextIcon className="w-8 h-8 mr-2" />
                Credit Application Details
            </h2>
            <div className="grid gap-y-2 border rounded-md">
                {fields.map((field, index) => (
                    <div
                        key={index}
                        className={`grid grid-cols-2 gap-x-4 py-2 px-4 ${index % 2 === 0 ? 'bg-gray-100' : ''
                            } `}
                    >
                        <span className="font-medium">{field.label}:</span>
                        <span className={`${field.label == 'Status' ? getStatusClass(field.value) : ''}`}>
                            {field.value !== undefined && field.value !== null
                                ? field.value
                                : <span className="text-yellow-500">Missing</span>}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
};

const OffersSection = ({ financingRequest, updateFinancingRequest, billId }) => {
    const [newOffers, setNewOffers] = useState([
        { termLength: '', interestRate: '', amount: '' },
        { termLength: '', interestRate: '', amount: '' },
        { termLength: '', interestRate: '', amount: '' },
    ]);

    // Find the accepted offer, if any
    const acceptedOffer = financingRequest.offers?.find(
        (offer) => offer.status === 'Accepted'
    );

    // Determine if the financing request is approved
    const isApproved =
        financingRequest.status === 'Approved' || financingRequest.approved === true;

    useEffect(() => {
        if (acceptedOffer && isApproved) {
            // Only show the accepted offer
            setNewOffers([acceptedOffer]);
        } else {
            const defaultOffer = { termLength: '', interestRate: '', amount: '' };
            const offers = financingRequest.offers || [];

            // Ensure there are always 3 offers by adding empty ones if needed
            const updatedOffers = [...offers];
            while (updatedOffers.length < 3) {
                updatedOffers.push({ ...defaultOffer }); // Create a new object
            }

            // Make sure all offers are unique objects
            const uniqueOffers = updatedOffers.slice(0, 3).map((offer) => ({ ...offer }));

            setNewOffers(uniqueOffers);
        }
    }, [financingRequest.offers, isApproved]);

    const handleSaveOffers = async () => {
        try {
            // Remove empty offers
            const validOffers = newOffers.filter(
                (offer) => offer.termLength && offer.interestRate && offer.amount
            );

            // Update the financingRequest with the new offers
            const updatedFinancingRequest = {
                ...financingRequest,
                offers: validOffers,
            };
            console.log(updatedFinancingRequest);

            // Save to API via parent function
            await updateFinancingRequest(updatedFinancingRequest);

            // Update local state
            setNewOffers(validOffers);
            toast.success('Offers saved successfully');
        } catch (err) {
            toast.error('Failed to save offers');
        }
    };

    const handleOfferChange = (index, field, value) => {
        const updatedOffers = [...newOffers];
        updatedOffers[index] = { ...updatedOffers[index], [field]: value };
        setNewOffers(updatedOffers);
    };

    const handleNotifyCustomer = async () => {
        try {
            const response = await api_post(
                `${process.env.REACT_APP_API_URL}/admin/financing-request/email/${billId}`,
                {}
            );

            // Check if the response indicates success
            if (response.status === 'success') {
                toast.success('Offers sent to customer');
            } else {
                // Handle non-successful responses
                toast.error('Failed to send offers');
                console.error('API responded with:', response);
            }
        } catch (err) {
            // Catch network errors or other unexpected errors
            toast.error('Failed to send offers');
            console.error(err);
        }
    };

    return (
        <div>
            {acceptedOffer && isApproved ? (
                <>
                    <h2 className="text-3xl font-medium mb-4">Accepted Offer</h2>
                    <div className="border-4 border-green-500 p-4 mb-4">
                        <div className="grid grid-cols-3 gap-4 mb-4">
                            <div>
                                <label className="block font-medium mb-1">Term Length</label>
                                <input
                                    type="text"
                                    value={acceptedOffer.termLength}
                                    readOnly
                                    className="border rounded px-3 py-2 w-full"
                                />
                            </div>
                            <div>
                                <label className="block font-medium mb-1">Interest Rate</label>
                                <input
                                    type="text"
                                    value={acceptedOffer.interestRate}
                                    readOnly
                                    className="border rounded px-3 py-2 w-full"
                                />
                            </div>
                            <div>
                                <label className="block font-medium mb-1">Amount</label>
                                <input
                                    type="text"
                                    value={acceptedOffer.amount}
                                    readOnly
                                    className="border rounded px-3 py-2 w-full"
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <h2 className="text-3xl font-medium d mb-4">Add Offers</h2>
                    {newOffers.map((offer, index) => (
                        <div key={index} className="grid grid-cols-3 gap-4 mb-4">
                            <div>
                                <label className="block font-medium mb-1" htmlFor={`termLength-${index}`}>
                                    Term Length
                                </label>
                                <input
                                    type="text"
                                    id={`termLength-${index}`}
                                    placeholder="Term Length"
                                    className="border rounded px-3 py-2 w-full"
                                    value={offer.termLength}
                                    onChange={(e) =>
                                        handleOfferChange(index, 'termLength', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label className="block font-medium mb-1" htmlFor={`interestRate-${index}`}>
                                    Interest Rate
                                </label>
                                <input
                                    type="text"
                                    id={`interestRate-${index}`}
                                    placeholder="Interest Rate"
                                    className="border rounded px-3 py-2 w-full"
                                    value={offer.interestRate}
                                    onChange={(e) =>
                                        handleOfferChange(index, 'interestRate', e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label className="block font-medium mb-1" htmlFor={`amount-${index}`}>
                                    Amount
                                </label>
                                <input
                                    type="text"
                                    id={`amount-${index}`}
                                    placeholder="Amount"
                                    className="border rounded px-3 py-2 w-full"
                                    value={offer.amount}
                                    onChange={(e) =>
                                        handleOfferChange(index, 'amount', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    ))}
                    <div className="flex space-x-4">
                        <button
                            onClick={handleSaveOffers}
                            className="bg-blue-400 hover:bg-blue-500 text-white font-medium py-2 px-4 rounded"
                        >
                            Save Offers
                        </button>
                        <button
                            onClick={handleNotifyCustomer}
                            className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-2 px-4 rounded"
                        >
                            Notify Customer
                        </button>
                    </div>
                </>
            )}
        </div>
    );
};


export default FinancingRequestDetail;
