import { useEffect } from 'react';
import ReactDOM from 'react-dom';

export default function Modal({
  children,
  onClose,
  blur = false,
  darken = false,
  containerClassName= ""
}) {
  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    const handleOutsideClick = (event) => {
      if (event.target.id === 'modal-background') {
        document.body.classList.remove('overflow-hidden');
        onClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);
    return () => window.removeEventListener('click', handleOutsideClick);
  }, [onClose]);

  // check for esc key
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        document.body.classList.remove('overflow-hidden');
        onClose();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [onClose]);

  return ReactDOM.createPortal(
    <div
      id="modal-background"
      className={`fixed inset-0 z-50 flex items-center justify-center ${darken ? 'bg-black bg-opacity-50' : ''
        } ${blur ? 'backdrop-blur-sm' : ''}`}
    >
      <div className={`relative p-4 bg-white rounded shadow-lg ${containerClassName}`}>
        <button
          className="absolute top-0 right-0 mt-4 mr-4 text-gray-600 hover:text-gray-900 text-3xl bg-gray-100 rounded-full px-2 hover:bg-gray-200 transition-all border"
          onClick={() => {
            document.body.classList.remove('overflow-hidden');
            onClose();
          }}
        >
          &times;
        </button>
        {children}
      </div>
    </div>,
    document.body,
  );
}
