import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InlineWidget } from "react-calendly";
import { BanknotesIcon, BuildingLibraryIcon, DevicePhoneMobileIcon, WrenchScrewdriverIcon } from '@heroicons/react/20/solid';
import { api, api_post } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import { PhoneField, EINField, FileField, TextField, SelectField, CurrencyField } from './fields';
import { getUserAsCustomer } from '../utils';


export default function CreditApplicationForm() {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(1);
    const [completedSteps, setCompletedSteps] = useState([1]);
    const [disabled, setDisabled] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [calculatedQuoteAmount, setCalculatedQuoteAmount] = useState(null);
    const [isEinValid, setIsEinValid] = useState(false);
    const [isFormDisabled, setIsFormDisabled] = useState(false);

    async function handleNewApplication() {
        const response = await getUserAsCustomer();
        setFormData({
            applicationData: {
                companyLegalName: response.businessName,
            }
        })
    }

    useEffect(() => {
        if (!localStorage.getItem('settingsOnboarding')) {
            toast.success('Welcome to BuildrFi! Fill in your business information to get started.');
            localStorage.setItem('settingsOnboarding', 't');
        }
    }, []);

    const [formData, setFormData] = useState({
        applicationData: {
            // Page 1 fields
            companyLegalName: '',
            einNumber: '',
            dateOfIncorporation: '',
            companyAddress: '',
            phoneNumber: '',
            website: '',
            monthlyRevenue: '',
            monthlyDebtPayment: '',
            averageProjectValuePerMonth: '',
            typesOfProjectsYouWorkOn: '',
            // Page 2 fields
            bankAccountFile: null,
            quickbooksFile: null,
            projectManagementFile: null,
        }
    });

    const steps = [
        { step: 1, label: 'Tell us More About Your Business' },
        { step: 2, label: 'Upload Documents' },
        { step: 3, label: 'Create Project' },
    ];

    useEffect(() => {
        const fetchCreditApplication = async () => {
            try {
                const response = await api(`${process.env.REACT_APP_API_URL}/credit-application/application`);
                if (response?.applicationData) {
                    setFormData(response);
                    if (['pending', 'rejected', 'verified'].includes(response?.status?.toLowerCase())) {
                        setIsFormDisabled(true);
                    }
                } else {
                    // No application found
                    await handleNewApplication();
                }
            } catch (error) {
                console.error("Error fetching credit application:", error);
            }
        };

        // Call the async function inside useEffect
        fetchCreditApplication();
    }, []);



    const handleInputChange = (key, value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            applicationData: {
                ...prevFormData.applicationData,
                [key]: value
            }
        }));
    };

    // Updated function to calculate the quote amount using the new formula
    const calculateQuoteAmount = () => {
        const monthlyRevenue = parseFloat(formData.applicationData.monthlyRevenue.replace(/[^0-9.-]+/g, '')) || 0;
        const monthlyDebtPayment = parseFloat(formData.applicationData.monthlyDebtPayment.replace(/[^0-9.-]+/g, '')) || 0;
        const averageProjectValuePerMonth = parseFloat(formData.applicationData.averageProjectValuePerMonth.replace(/[^0-9.-]+/g, '')) || 0;
        const loanPercentage = 0.2; // 20%
        const depositPercentage = 0.25; // 25%

        const adjustedLiabilities = (monthlyDebtPayment * 3) - (averageProjectValuePerMonth * 3 * depositPercentage);
        const preApproval = (monthlyRevenue * 3 * loanPercentage) - adjustedLiabilities;

        const amount = preApproval;
        let finalAmount = amount > 0 ? amount : 1000;
        finalAmount = Math.min(finalAmount, 200000);
        return finalAmount;
    };

    // Function to get the quote amount based on the current step
    const getQuoteAmount = () => {
        if (currentStep === 3 && calculatedQuoteAmount !== null) {
            return calculatedQuoteAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        } else if (currentStep === 1) {
            return '$XX,XXX';
        } else if (currentStep === 2) {
            return '$XX,000';
        } else {
            return '';
        }
    };

    const saveApplicationData = () => {
        let data = { ...formData };
        if (currentStep === 2 && (data.status !== 'verified' || data.status !== 'rejected')) {
            data['status'] = 'pending';
        }
        api_post(`${process.env.REACT_APP_API_URL}/credit-application`, data).then((response) => {
            setFormData(response);
        })
    }

    const replayQuoteAnimation = () => {
        const quoteElement = document.getElementById('header-quote');
        quoteElement.classList.remove('animate-tada');
        void quoteElement.offsetWidth; // This forces a DOM reflow
        quoteElement.classList.add('animate-tada');
    }



    const handleNext = (e) => {
        e.preventDefault();
        setDisabled(true);

        if (currentStep === 1) {
            const {
                companyLegalName,
                dateOfIncorporation,
                companyAddress,
                phoneNumber,
                monthlyRevenue,
                monthlyDebtPayment,
                averageProjectValuePerMonth,
                typesOfProjectsYouWorkOn,
                einNumber
            } = formData.applicationData;

            if (
                !companyLegalName ||
                !einNumber ||
                !dateOfIncorporation ||
                !companyAddress ||
                !phoneNumber ||
                !monthlyRevenue ||
                !monthlyDebtPayment ||
                !averageProjectValuePerMonth ||
                !typesOfProjectsYouWorkOn
            ) {
                alert('Please fill in all mandatory fields.');
                setDisabled(false);
                return;
            }
        }

        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setCompletedSteps((prevSteps) => [...prevSteps, nextStep]);
        saveApplicationData();
        setDisabled(false);
        replayQuoteAnimation();
    };

    const handleNextStep2 = () => {
        // All sections are required
        const { bankAccountFile, quickbooksFile, projectManagementFile } = formData.applicationData;
        if (!bankAccountFile || !quickbooksFile || !projectManagementFile) {
            alert('Please upload files for all sections.');
            return;
        }
        saveApplicationData();
        setShowModal(true);
    };

    const handleBack = () => {
        const prevStep = currentStep - 1;
        if (prevStep >= 1) {
            setCurrentStep(prevStep);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        const nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        setCompletedSteps((prevSteps) => [...prevSteps, nextStep]);
        const amount = calculateQuoteAmount();
        setCalculatedQuoteAmount(amount);
    }

    const Tooltip = ({ text }) => {
        const [hover, setHover] = useState(false);
        return (
            <span className="relative">
                <span
                    className="ml-1 text-gray-400 cursor-pointer"
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    ?
                </span>
                {hover && (
                    <div className="absolute z-10 p-2 mt-1 text-sm text-white bg-black rounded">
                        {text}
                    </div>
                )}
            </span>
        );
    };

    // Helper function to render an integration section
    const renderIntegrationSection = (
        { key,
            serviceName,
            fileLabel,
            uploadLabel,
            file,
            setFile,
            comingSoonImage,
            integrationName,
            renderIcon
        }
    ) => {
        let isCompleted = (file && file !== null) ? true : false
        const borderClass = isCompleted ? 'border-green-400' : 'border-gray-300';
        return (
            <div key={key} className={`flex items-center justify-between border-4 relative ${borderClass} rounded-md px-2 py-6 mb-4 bg-white min-h-48 transition-all ${isCompleted ? 'bg-white' : 'bg-opacity-80'}`}>
                {renderIcon}
                <div className="w-1/2 overflow-hidden flex flex-col items-center">
                    <FileField
                        label={fileLabel}
                        value={file}
                        onChange={(val) => { setFile(val); isCompleted = true }}
                        required
                        fileName={file?.name}
                        source="creditApplication"
                        className={"w-3/4"}
                        labelClass={"text-xs"}
                        hideNote={true}
                        disabled={isFormDisabled}
                    />
                </div>

                <div className="mx-4 text-gray-500">Or</div>

                <div className="text-sm w-1/2 flex flex-col items-center">
                    <button
                        className="px-6 py-3 rounded-md bg-gray-300 flex items-center justify-start cursor-not-allowed text-sm w-3/4 text-center font-semibold transition-all text-opacity-30"
                        disabled
                    >
                        <img src={comingSoonImage} alt={integrationName} className="w-6 h-6 mr-2 bg-white rounded-full p-0.5" />
                        {integrationName} <span className='text-gray-500 text-xs ml-1 font-medium'>(Coming Soon)</span>
                    </button>
                </div>
            </div>
        );
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return (
                    <form onSubmit={handleNext}>
                        <div className="grid grid-cols-1 gap-y-5 gap-x-16 sm:grid-cols-2">
                            {/* Company Legal Name */}
                            <TextField
                                label="Company Legal Name"
                                value={formData.applicationData.companyLegalName}
                                onChange={(val) => handleInputChange('companyLegalName', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* EIN Number */}
                            <EINField
                                label="EIN Number"
                                value={formData.applicationData.einNumber}
                                onChange={(val) => handleInputChange('einNumber', val)}
                                onValid={(isValid) => setIsEinValid(isValid)}  // Capture validity if needed
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />

                            {/* Date of Incorporation */}
                            <TextField
                                label="Date of Incorporation"
                                type="date"
                                value={formData.applicationData.dateOfIncorporation}
                                onChange={(val) => handleInputChange('dateOfIncorporation', val)}
                                required
                                inputClass="border-gray-200 mt-1 text-xs"
                                disabled={isFormDisabled}
                            />
                            {/* Company Address */}
                            <TextField
                                label="Company Address"
                                value={formData.applicationData.companyAddress}
                                onChange={(val) => handleInputChange('companyAddress', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Phone Number */}
                            <PhoneField
                                label="Phone Number"
                                value={formData.applicationData.phoneNumber}
                                onChange={(val) => handleInputChange('phoneNumber', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Website */}
                            <TextField
                                label="Website"
                                value={formData.applicationData.website}
                                onChange={(val) => handleInputChange('website', val)}
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Monthly Revenue */}
                            <CurrencyField
                                label="Monthly Revenue"
                                value={formData.applicationData.monthlyRevenue}
                                onChange={(val) => handleInputChange('monthlyRevenue', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Monthly Debt Payment */}
                            <CurrencyField
                                label="Monthly Debt Payment"
                                value={formData.applicationData.monthlyDebtPayment}
                                onChange={(val) => handleInputChange('monthlyDebtPayment', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Average Project Value Per Month */}
                            <CurrencyField
                                label="Average Project Value Per Month"
                                value={formData.applicationData.averageProjectValuePerMonth}
                                onChange={(val) => handleInputChange('averageProjectValuePerMonth', val)}
                                required
                                inputClass={"text-xs"}
                                disabled={isFormDisabled}
                            />
                            {/* Types of Projects You Work On */}
                            <SelectField
                                label="Types of Projects You Work On"
                                value={formData.applicationData.typesOfProjectsYouWorkOn}
                                onChange={(val) => handleInputChange('typesOfProjectsYouWorkOn', val)}
                                options={[
                                    { value: '', text: 'Select an option' },
                                    { value: 'Residential', text: 'Residential' },
                                    { value: 'Commercial', text: 'Commercial' },
                                    { value: 'Both', text: 'Both' },
                                ]}
                                required
                                disabled={isFormDisabled}
                            />
                        </div>
                        <div className="mt-6 flex space-x-4">
                            <button
                                type="submit"
                                className={`px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold ${disabled ? 'opacity-50' : ''}`}
                                disabled={disabled}
                            >
                                Save and Continue
                            </button>
                        </div>
                    </form>
                );
            case 2:
                return (
                    <div className='max-w-1/2'>
                        {renderIntegrationSection({
                            key: 'bankAccount',
                            serviceName: 'Bank Statements',
                            fileLabel: 'Upload Bank Statements (Last 6 months)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.bankAccountFile,
                            setFile: (file) => handleInputChange('bankAccountFile', file),
                            comingSoonImage: 'bank-coming-soon.png', // Image for coming soon
                            renderIcon: <BuildingLibraryIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />,
                            integrationName: "Bank Account"
                        })}
                        {renderIntegrationSection({
                            key: 'quickbooks',
                            serviceName: 'Financial Statements',
                            fileLabel: 'Upload Financial Statements (Last Year)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.quickbooksFile,
                            setFile: (file) => handleInputChange('quickbooksFile', file),
                            comingSoonImage: 'quickbooks-coming-soon.png',
                            integrationName: "Quickbooks",
                            renderIcon: <BanknotesIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />
                        })}

                        {renderIntegrationSection({
                            key: 'projectManagement',
                            serviceName: 'Work In Progress',
                            fileLabel: 'Upload WIP Report (Most Recent)',
                            uploadLabel: 'Upload',
                            file: formData.applicationData.projectManagementFile,
                            setFile: (file) => handleInputChange('projectManagementFile', file),
                            comingSoonImage: 'project-management-coming-soon.png',
                            integrationName: "Projects",
                            renderIcon: <WrenchScrewdriverIcon className="w-6 h-6 mr-2 text-gray-500 absolute top-2 right-2" />
                        })}
                        <div className="mt-6 flex space-x-4">
                            <button
                                type="button"
                                onClick={handleBack}
                                className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 font-semibold"
                            >
                                Back
                            </button>
                            <button
                                type="button"
                                onClick={handleNextStep2}
                                className={`px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold ${disabled ? 'opacity-50' : ''
                                    }`}
                                disabled={disabled}
                            >
                                Submit
                            </button>
                        </div>
                        {/* Modal */}
                        {showModal && (
                            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white rounded-lg text-center overflow-hidden max-w-[640px] pb-10">
                                    <img src="mail.jpg" alt="Header" className=" w-[640px] mb-10" onClick={() => closeModal()} />
                                    <h2 className="text-3xl font-bold mb-4">
                                        You are Pre-Approved for{' '}
                                        <span className='inline-block animate-tada' >{calculateQuoteAmount().toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })}!</span>
                                    </h2>
                                    <p className="my-8 px-4">
                                        Get ready to build with {calculateQuoteAmount().toLocaleString('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        })} in pre-approved financing! We will reach out to you within <strong>24 hours</strong> regarding your acceptance to the BuildrFi financing program.                                    </p>
                                    <button
                                        className="px-4 py-2 bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold"
                                        onClick={() => {
                                            closeModal()
                                        }}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                );
            case 3:
                return (
                    <div className="text-center h-full flex flex-col items-center justify-center pb-16">
                        <h2 className="text-4xl font-bold mb-4 mt-36">You're all set!</h2>
                        <h3 className="text-xl font-semibold mb-4">
                            Your pre-approved financing amount: {getQuoteAmount()}
                        </h3>
                        <p className="mb-6">Create your first project to get started.</p>
                        <button
                            className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all"
                            onClick={() => {
                                navigate('/project/new?rd=/projects');
                            }}
                        >
                            Create Project
                        </button>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="lg:max-w-6xl mx-auto xl:p-6 bg-white rounded-lg">
            <h2 className="text-2xl font-bold mb-4">
                Unlock your pre-approval <span id="header-quote" className='text-4xl ml-2 animate-tada inline-block'>{getQuoteAmount() && `${getQuoteAmount().toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`}</span>
            </h2>
            <div className="flex bg-yellow-50 rounded-md p-6 border">
                <div className="w-2/3 max-w-[2/3]">
                    <div className="flex space-x-4 mb-6">
                        {steps.map((item) => (
                            <button
                                key={item.step}
                                className={`py-2 px-4 rounded-md ${currentStep === item.step
                                    ? 'bg-yellow-500 text-black font-semibold'
                                    : 'bg-gray-200 text-gray-700'
                                    } ${completedSteps.includes(item.step)
                                        ? ''
                                        : 'cursor-not-allowed opacity-50'
                                    }`}
                                onClick={() => {
                                    if (completedSteps.includes(item.step)) {
                                        setCurrentStep(item.step);
                                    }
                                }}
                                disabled={!completedSteps.includes(item.step)}
                            >
                                {item.label}
                            </button>
                        ))}
                    </div>
                    <div className=''>
                        {renderStepContent(currentStep)}
                    </div>
                </div>
                {/* Sidebar */}
                <div className="w-1/3 max-w-[1/3] pl-4">
                    <div className="bg-white p-4 rounded shadow">
                        <div className="flex">
                            <img
                                src="steph.png"
                                alt="Stephanie"
                                className="rounded-full w-20 h-20 mr-3"
                            />
                            <h3 className="text-base lg:text-xl font-bold mb-4 mt-3">Have questions? Let's Talk:</h3>
                        </div>
                        <div className='w-full flex flex-col items-center justify-center'>
                            <p className="text-sm flex items-center mt-3 text-gray-700">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 mr-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                                </svg>
                                stephanie@buildrfi.com</p>
                            <p className="text-sm flex text-gray-700 mt-2">
                                <DevicePhoneMobileIcon className="w-5 h-5 mr-1" />
                                786-351-4823</p>
                        </div>

                        <InlineWidget styles={{ height: "500px" }} pageSettings={{
                            backgroundColor: 'ffffff',
                            hideEventTypeDetails: true,
                            hideLandingPageDetails: true,
                        }} url="https://calendly.com/stephanie-buildrfi/let-s-chat?primary_color=f6cc45" />
                    </div>
                </div>
            </div>
        </div>
    );
}
