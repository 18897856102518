import { useState } from 'react';
import { api } from '../../api.service';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFile } from '../../service/files';

export default function FileField({
  label,
  value,
  onChange,
  className,
  labelClass,
  source,
  hideNote = false,
  disabled = false,
}) {
  const [documentFile, setDocumentFile] = useState(value ?? {});
  const [loading, setIsLoading] = useState(null);

  const startLoading = () => {
    setIsLoading(0);
    const interval = setInterval(() => {
      setIsLoading((prev) => {
        if (prev === 97) {
          clearInterval(interval);
          return 97;
        }
        if (prev === 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 1;
      });
    }, 100);
  };

  const finishLoading = () => {
    setIsLoading(100);
    setTimeout(() => {
      setIsLoading(null);
    }, 2000);
  };

  const handleUploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const sourceData = { source };
    formData.append('sourceData', JSON.stringify(sourceData));
    startLoading();
    await uploadFile(formData)
      .then((response) => {
        finishLoading();
        onChange(response._id);
        setDocumentFile(response);
        toast.success('Document uploaded successfully');
      })
      .catch((error) => {
        finishLoading();
        toast.error('Document upload failed');
      });
  };

  const handleDownloadDocument = async () => {
    const response = await api(
      `${process.env.REACT_APP_API_URL}/document/download-url?key=${documentFile.key}`,
    );

    const link = document.createElement('a');
    link.href = response.url;
    link.setAttribute('download', response.url);

    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  return (
    <div className={className}>
      <ToastContainer position="top-center" autoClose={2000} />
      <div
        className={`block text-sm font-medium leading-6 text-neutral-800 ${labelClass}`}
      >
        {label}
      </div>
      <div className="flex flex-col">
        <div
          onClick={handleDownloadDocument}
          className="flex-1 block text-xs font-medium text-left underline cursor-pointer text-neutral-700 animate-fade"
        >
          {documentFile && <span>{documentFile.key}</span>}
        </div>
        {documentFile.key && hideNote === false && (
          <div>
            <p className="text-xs opacity-50 mt-1">
              Note - please submit the form to save the file.
            </p>
          </div>
        )}
      </div>
      {loading !== null && (
        <div className="relative w-full h-2 mt-2 bg-neutral-200 rounded animate-fade">
          <div
            className="absolute top-0 left-0 h-2 bg-yellow-500 rounded"
            style={{ width: `${loading}%` }}
          ></div>
        </div>
      )}
      {!disabled && <label>
        <input
          onChange={async (e) => await handleUploadFile(e.target.files[0])}
          type="file"
          name="file"
          className="w-0 h-0 opacity-0"
        />
        <div className="p-2 text-sm font-medium text-center bg-yellow-500 rounded shadow cursor-pointer text-neutral-700 hover:text-neutral-900">
          <span>{documentFile.key ? 'Replace' : 'Upload'} File</span>
        </div>
      </label>}
    </div>
  );
}
