import { useEffect, useRef, useState } from 'react';
import { api } from '../api.service.js';
import Loading from '../components/loading';

export default function FormWrapper({
  url,
  id,
  callback,
  children,
  includeIdInPost,
  buttonText,
  hideButton,
  noStyle,
  defaultValue,
  noId,
  noLoad,
  validate,
  extraBodyData = {},
}) {
  const [values, setValues] = useState();

  window.onbeforeunload = function () {
    return '';
  };

  // Retrieving entity information (for fields autocomplete)
  useEffect(() => {
    if (noId) {
      if (noLoad) {
        setValues(defaultValue ?? {});
      } else {
        api(`${process.env.REACT_APP_API_URL}/${url}`).then((x) => {
          setValues(x ?? {});
        });
      }
    } else {
      if (id !== 'new')
        api(`${process.env.REACT_APP_API_URL}/${url}/${id}`).then((x) => {
          const isARequestForRolesEndpoint = !!x?.roles?._id;
          const isARequestForVendorsEndpoint = !!x?.vendor;
          const isARequestForCustomersEndpoint = !!x?.customer;

          setValues(
            {
              ...x,
              ...(isARequestForRolesEndpoint && { roles: x.roles._id }),
              ...(isARequestForVendorsEndpoint && { existentVendor: x.vendor }),
              ...(isARequestForCustomersEndpoint && {
                existentCustomer: x.customer,
              }),
            } ?? {},
          );
        });
      else {
        setValues(defaultValue ?? {});
      }
    }
  }, [id]);

  // Submitting entity information
  const submit = (data) => {
    if (validate && !validate(values)) return;
    const bodyData = data ?? values;
    const fullBodyData = { data: { ...bodyData, ...extraBodyData } };

    if (includeIdInPost && !noId) {
      api(`${process.env.REACT_APP_API_URL}/${url}/${id}`, fullBodyData).then(
        (x) => {
          callback(x);
        },
      );
    } else {
      api(`${process.env.REACT_APP_API_URL}/${url}`, fullBodyData).then((x) => {
        callback(x);
      });
    }
  };

  if (!values) return <Loading></Loading>;

  return (
    <div
      className={
        noStyle
          ? ''
          : '2xl:max-w-6xl max-w-5xl mx-auto mb-5 bg-secondary shadow rounded p-5 animate-fade border'
      }
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
        className='relative'
      >
        {children(values, setValues, submit)}
        <div className="flex justify-end">
          {!hideButton && (
            <button className="inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium bg-yellow-500 border-2 border-yellow-500 rounded cursor-pointer text-neutral-800 hover-lift ">
              {buttonText ?? 'Submit'}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}
